.centeredTableHeader {
  text-align: center;
  font-size: 24px; 
  font-weight: bold;
}

.attendanceForOrganizationSectionContainer {
  flex-grow: 1;
  width: 80%;
}

.stickyColumn {
  position: sticky;
  top: 0;
  left: 0;
  min-width: 150px;
  background-color: inherit;
  z-index: 10;
}


.stickyColumn::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 3px;
  width: 3px;  
  background-color: rgba(235, 235, 235, 0.5);
}

tbody .stickyColumn {
  z-index: 9;
}