.attendanceReportContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 15px;
  gap: 20px;
}

.stripContainer {
  flex-grow: 0;
  width: 260px;
}

.insertDetailsBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.insertDetailsBox input,
.insertDetailsBox select {
  border-radius: 5px;
  padding: 2.5px 5px;
  border: 0.1px rgb(199, 199, 199) solid;
}

.insertDetailsBox label {
  color: rgb(100, 100, 100);
}
