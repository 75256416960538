* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'RobotoSlabRegular';
}

html {
  font-size: 14px;
}
