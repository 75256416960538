.scheduleModule {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  grid-template-areas:
    'scheduleTableContainer scheduleTableContainer scheduleTableContainer scheduleTableContainer scheduleDetailsSelection';
  height: 100%;
  padding: 15px;
  gap: 20px;
}

.scheduleTableContainer {
  grid-area: scheduleTableContainer;
  min-width: 0;
  min-height: 0;
}

.scheduleDetailsSelection {
  grid-area: scheduleDetailsSelection;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 70px);
  position: relative;
}

.scheduleCalendarContainer {
  height: fit-content;
}

.scheduleCalendarContainer div {
  margin: 0 auto;
  color: rgb(218, 116, 0);
}

.scheduleScrollableBox {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto;
  height: 100%;
}

.scheduleScrollableBox::before {
  content: "";
  position: absolute;
  top: 353px;
  width: 100%;
  left: 0;
  width: 280px;
  height: 20px;
  background: white;
  border-radius: 10px 0 0 0;
}

.scheduleScrollableBox::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  width: 280px;
  height: 20px;
  background: white;
  border-radius: 0 0 0 10px;
}


.rmdp-shadow {
  box-shadow: none !important;
}

.rmdp-today span {
  color: black !important;
  background-color: transparent !important;
}

.rmdp-selected span {
  background-color: var(--orange) !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  box-shadow: none !important;
}

.scheduleContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  gap: 20px;
}