/* main layout */
.studentModule {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-template-areas:
    'profile classrooms notes notes'
    'profile classrooms notes notes'
    'profile classrooms notes notes'
    'profile classrooms statuses attachments'
    'profile classrooms statuses attachments';
  height: 100%;
  padding: 15px;
  gap: 20px;
}

.profile {
  grid-area: profile;
}

.classrooms {
  grid-area: classrooms;
  min-width: 0;
  min-height: 0;
}

.attachments {
  grid-area: attachments;
  min-width: 0;
  min-height: 0;
}

.statuses {
  grid-area: statuses;
  min-width: 0;
  min-height: 0;
}

.notes {
  grid-area: notes;
}

.studentScrollableBox {
  position: relative;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/* input includes date type */
.studentScrollableBox input,
.studentScrollableBox select {
  border-radius: 5px;
  padding: 2.5px 5px;
  border: 0.1px rgb(199, 199, 199) solid;
}

.studentScrollableBox label {
  color: rgb(100, 100, 100);
}

/* .studentScrollableBox input[type='date']::-webkit-datetime-edit-month-field,
.studentScrollableBox input[type='date']::-webkit-datetime-edit-day-field,
.studentScrollableBox input[type='date']::-webkit-datetime-edit-year-field {
  color: rgb(175, 175, 175);
} */

/* main layout */

/* student details section  */
/* student images  */
.studentProfileImageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.profileImageRibbon {
  position: absolute;
  width: 70px;
  z-index: 0;
}

.leftRibbon {
  left: 15px;
}

.rightRibbon {
  right: 15px;
}

.studentProfileImageBox {
  background-color: white;
  border-radius: 50%;
  display: flex;
  height: 160px;
  width: 160px;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
}

.studentProfileImageBox img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 5px 5px 0px inset rgb(172, 172, 172);
}

.studentProfileImageInput {
  display: none;
}

.studentProfileImageLabel {
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  left: 0px;
  top: 0px;
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* student images  */

.studentDetailsBox {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
/* student details section  */

/* student classrooms and statuses */
.studentActiveCardContainer {
  border-radius: 10px;
  border: 0.1px solid rgb(200, 200, 200);
  border-left: 8px solid var(--mainbg);
  transition: all 0.8s;
}

.studentInactiveCardContainer {
  box-shadow: 0px 0px 5px 0px inset rgb(170, 170, 170);
  border-radius: 10px;
  transition: all 0.8s;
}

.studentCardDeleteConfirmationActiveBox {
  border-bottom: 0.1px solid rgb(200, 200, 200);
  margin-bottom: 20px;
  background: rgb(233, 233, 233);
  display: flex;
  flex-direction: column;
  border-radius: 0px 10px 0px 0px;
  gap: 10px;
  padding: 20px;
}

.studentCardDeleteConfirmationInactiveBox {
  border-bottom: 0.1px solid rgb(200, 200, 200);
  margin-bottom: 20px;
  background: rgb(233, 233, 233);
  display: flex;
  flex-direction: column;
  border-radius: 10px 10px 0px 0px;
  gap: 10px;
  padding: 20px;
}

.confirmationButtonsContainer {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.confirmationButtonsContainer button {
  padding: 5px 25px;
  min-width: 100px;
  max-width: 100px;
  border: none;
  box-shadow: 0px 2.5px 2.5px 0 rgb(174, 174, 174);
  color: white;
  background-color: var(--orange);
  border-radius: 10px;
  cursor: pointer;
  letter-spacing: 1px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.studentCardConfirmationButtonImage {
  height: 14px;
  width: 15px;
}

.confirmationButtonsContainer button:hover {
  background-color: var(--orange_hover);
  transition: all 0.4s ease-in-out;
}

.studentCardBox {
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
  justify-content: space-between;
  gap: 20px;
}

.studentCardBoxDeleteButtonContainer {
  display: flex;
  padding: 2.5px;
  width: 100%;
}

.studentCardBoxDeleteButton {
  margin-left: auto;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
}

.studentCardBoxDeleteImage {
  height: 20px;
  width: 20px;
  opacity: 0.08;
}

.studentCardBoxDeleteImage:hover {
  opacity: 0.5;
}

.studentInactiveCardContainer input,
.studentInactiveCardContainer select,
.studentInactiveCardContainer label {
  opacity: 0.3;
  transition: color 0.8s;
}

.studentInactiveCardContainer
  input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0.3;
}

.studentCardSubSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.studentCardSubSection input,
.studentCardSubSection select {
  width: 120px;
}

.studentCardExtraSection {
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgb(231, 231, 231);
  border-radius: 0 0 5px 5px;
}

.studentInactiveCardContainer .classroomStatus:hover {
  border: 0.5px solid rgb(200, 203, 250);
  color: initial;
}
/* student classrooms and statuses */

/* student notes */
.noteContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.noteInputBox {
  display: inherit;
  flex-direction: inherit;
  gap: 10px;
  width: 100%;
}

.noteHeaderCategoriesContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.noteCategoriesContainer {
  display: flex;
  gap: 10px;
  align-self: flex-end;
}

.noteTextarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  border-radius: 10px;
  border: 0.1px rgb(100, 100, 100) solid;
  resize: none;
  font-size: 1rem;
}

.noteInfoControlsContainer {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

.noteInfoControlsContainer div:nth-child(2) {
  justify-content: flex-end;
}

.noteCategoryButton {
  padding: 10px 20px;
  text-transform: capitalize;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 2.5px 2.5px 0 rgb(174, 174, 174);
  border: 1px solid wheat;
  border: none;
  background-color: rgb(255, 255, 255);
}

.chosenNoteCategory {
  background-color: rgb(255, 255, 255);
  box-shadow: none;

  box-shadow: 0px 5px 5px 0px inset rgb(174, 174, 174);
}

.noteButtons {
  display: flex;
}

.noteIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  background-color: transparent;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.noteIcon {
  width: 100%;
  height: 100%;
}

.filterCategoriesContainer {
  display: flex;
  gap: 20px;
}
/* student notes */

/* student attachments */
.maskedAttachmentInput {
  width: 25px;
  height: 25px;
  opacity: 0;
  position: absolute;
  z-index: -1;
  outline: none;
  border: none;
}

.studentAttachmentsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.studentAttachmentContainer {
  display: flex;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 0px 10px 10px 0px;
  gap: 5px;
  justify-content: space-between;
}

.studentAttachmentInfoBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0 10px 10px;
}

.studentAttachmentButtonsContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.attachmentDownloadBox {
  width: 25px;
  height: 25px;
  background-color: var(--orange);
  border-radius: 0px 0px 5px 0px;
  cursor: pointer;
  border: none;
  display: flex;
}

.attachmentDownloadBox img {
  width: 25px;
  height: 25px;
}

.studentAttachmentDeleteConfirmationBox {
  background: rgb(233, 233, 233);
  display: flex;
  flex-direction: column;
  border-radius: 0px 10px 10px 0px;
  gap: 10px;
  padding: 20px;
}

/* student attachments */

/* Close modal */
.closeModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(170, 133, 68, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.closeConfirmationBox {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 10px;
}

.closeConfirmationBox p {
  font-size: 1.2rem;
  /* text-align: center; */
}

.confirmationButtonsContainer .annuleren_btn {
  max-width: fit-content;
  padding: 5px 15px;
}

.confirmationButtonsContainer .opslaan_btn {
  max-width: fit-content;
  /* padding: 5px 15px; */
}