.studentsListFiltersContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 20px;
  height: calc(100% - 40px);
}

.studentsFilterContainer {
  width: 300px;
  min-width: 300px;
  height: 100%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: calc(100wh - 125px);
  box-shadow: 0px 2.5px 2.5px 0 rgb(174, 174, 174);
  padding: 5px;
}

.filterCollapseHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: bold;
  cursor: pointer;
  padding: 5px 10px;
  align-items: center;
}

.studentsFilterBox {
  margin: 5px 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* .filterCollapseHeader button {
  outline: none;
  border: none;
  background-color: transparent;
} */

.filterSectionTitle {
  font-size: 1.1rem;
}

.filterCategories {
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 10px;
}

.filterCategoryBox {
  display: flex;
  gap: 5px;
  align-items: center;
}

.downloadAllStudentsPopupContainer {
  position: absolute;
  top: 10px;
  left: -60px;
  right: 10px;
  bottom: 10px;
  background: linear-gradient(
    0deg,
    var(--yellowish) 100%,
    var(--orange) 10%
  );
  border-radius: 10px;
  z-index: 21;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(2.5px);
}

.downloadAllStudentsPopup {
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 250px;
  min-height: 180px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  gap: 20px;
}
