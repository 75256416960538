.container_for_authContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgb(255, 228, 177); */
  background-color: white;
  display: flex;
}

.authContainer {
  position: relative;
  margin: auto;
  /* height: 500px; */
  width: 300px;
  border-radius: 10px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  /* background-color: rgb(255, 255, 255); */
  background-color: rgb(252, 247, 231);
  display: flex;
  flex-direction: column;
  padding: 55px 30px 50px 30px;
}

.organizationNamePrefix {
  font-size: 30px;
  color: rgba(69, 69, 69, 0.8);
  font-family: 'CormorantGaramondRegular';
}

.organizationName {
  text-shadow: 1px 1px 3px rgb(169, 141, 0);
  font-family: 'ProzaLibreRegular';
  font-size: 26px;
  letter-spacing: 1px;
  color: rgba(219, 110, 0, 0.8);
}

.authErrorMessageContainer {
  height: 60px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
}

.authErrorMessage {
  border: 1px solid rgb(235, 50, 35);
  color: rgb(235, 50, 35);
  background-color: rgb(239, 238, 238);
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  margin: auto 0;
  font-size: 1rem;
}

.authInputsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.headwords {
  margin-left: 90px;
  z-index: 1;
}

.logo_container {
  position: relative;
  width: calc(100% + 60px);
  margin-left: -30px;
  /* background: rgb(255, 211, 128); */
  padding: 10px;

  display: flex;
}

.login_logo {
  width: 100%;
  height: 100%;
  /* position: absolute;
  object-fit: fill;
  border-radius: 10px;
  width: 75px; 
  height: 75px; 
  background-color: transparent;
  padding: 5px;
  margin-bottom: 20px; */
}

.authInputsContainer input {
  padding: 5px;
  text-align: center;
  /* border-radius: 5px;
  border: 1px solid transparent; */
}


/* .authInputsContainer input {
  padding: 5px;
  text-align: center;
  border-radius: 5px;
  color: rgb(190, 76, 0) !important;
  border: 2px solid var(--orange);
  background-color: rgb(212, 212, 18) !important;
  background-color: rgb(254, 239, 190) !important;
  font-size: 1rem;
}

.authInputsContainer input::placeholder {
  color: rgb(190, 76, 0);
  background-color: rgb(254, 239, 190) !important;
  font-size: 1rem;
}

.authInputsContainer input:focus::placeholder {
  background-color: rgb(254, 239, 190) !important;
}

.authInputsContainer input:focus {
  background-color: none;
  outline: none;
  border: 2px solid rgb(201, 81, 0);
  color: rgb(190, 76, 0) !important;
} */

.authButton {
  padding: 8px 0;
  border-radius: 5px;
  box-shadow: 0px 2.5px 5px rgb(170, 170, 170);
  background-color: var(--orange);
  color: white;
  border: 0px solid transparent;
  font-size: 1rem;
  cursor: pointer;
}

.authButton:hover {
  background-color: var(--orange_hover);
  transition: all 0.4s ease-in-out
}

.authPersistContainer {
  margin: 10px 0;
  display: flex;
  gap: 5px;
}

.authPersistContainer label {
  font-size: 1rem;
}

.authForgotPassword {
  /* background-color: rgb(254, 239, 190); */
  /* padding: 10px; */
  border-radius: 5px;
  color: var(--orange);
  text-align: center;
  text-decoration: none;
  /* font-family: 'ProzaLibreRegular'; */
  font-size: 1rem;
  margin-top: 20px;
  padding: 7.5px 10px;
  border: 0.5px solid var(--orange);
}

/* forgot password */
.forgetPasswordContainer {
  /* height: fit-content; */
  width: 360px;
  background-color: rgb(250, 250, 250);
  border-radius: 10px;
  box-shadow: 0px 4px 4px rgb(170, 170, 170);
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 40px;
  gap: 15px;
}

.authText {
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
}