.printContainer {
  display: none;
}

@media print {
  nav {
    display: none;
  }

  .popupContainer {
    display: none;
  }
  @font-face {
    font-family: 'CormorantGaramondRegular';
    src: local('CormorantGaramondRegular'),
      url('../fonts/Cormorant_Garamond/CormorantGaramond-Regular.ttf')
        format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'CormorantGaramondBold';
    src: local('CormorantGaramondBold'),
      url('../fonts/Cormorant_Garamond/CormorantGaramond-Bold.ttf')
        format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'RobotoSlabRegular';
    src: local('RobotoSlabRegular'),
      url('../fonts/Roboto_Slab/RobotoSlab-Regular.ttf') format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'RobotoSlabBold';
    src: local('RobotoSlabBold'),
      url('../fonts/Roboto_Slab//RobotoSlab-Bold.ttf') format('truetype');
    font-weight: bold;
  }

  @font-face {
    font-family: 'ProzaLibreRegular';
    src: local('ProzaLibreRegular'),
      url('../fonts/Proza_Libre/ProzaLibre-Regular.ttf') format('truetype');
    font-weight: regular;
  }

  * {
    font-family: 'RobotoSlabRegular';
  }

  .printContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    gap: 40px;
    /* background-color: rgb(196, 196, 196);
    print-color-adjust: exact; */
    height: 100%;
  }

  .printSection {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.25);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    /* page-break-inside: avoid; */
    page-break-after: always;
    /* page-break-before: auto; */
    height: 100%;
  }

  .printSectionHeaderContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
    width: 100%;
  }

  .printSectionTitleContainer {
    display: flex;
    gap: 10px;
  }

  .printSectionTitle {
    font-size: 1.4rem;
  }

  .printSectionTitlePoint {
    height: 25px;
    width: 10px;
    background-color: rgb(200, 203, 250);
    print-color-adjust: exact;
    border-radius: 5px;
    box-shadow: 0px 2.5px 2.5px 0 inset rgb(154, 154, 154);
  }

  .printMainInfoContainer {
    display: flex;
    /* align-items: center; */
    gap: 40px;
    /* justify-content: space-between; */
  }

  .printableProfileImage {
    /* background: #34495e; */
    /* border: 1px solid #34495e; */
    width: 250px;
    height: 250px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    background-color: rgb(200, 203, 250);
    print-color-adjust: exact;
    box-shadow: 0px 0px 2.5px 0 rgb(154, 154, 154);
    padding: 8px;
  }

  .printMainInfo {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .printMainInfo div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .printStudentClassrooms {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }

  .printStudentClassroomCard {
    width: 200px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid rgb(165, 165, 165);
    border-left: 8px solid rgb(200, 203, 250);
    gap: 10px;
    page-break-inside: avoid;
  }

  .printStudentClassroomCard div {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .printMainInfo,
  .printStudentClassroomCard,
  .printStudentNoteCard {
    font-size: 1.2rem;
  }

  .printMainInfo i,
  .printStudentClassroomCard i,
  .printStudentNoteCard i {
    font-family: 'CormorantGaramondRegular';
  }

  .printStudentNotes {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }

  .printStudentNoteCard {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid rgb(165, 165, 165);
    border-left: 8px solid rgb(200, 203, 250);
    gap: 10px;
    page-break-inside: avoid;
  }

  .printStudentCardHeaderContainer {
    display: flex;
    justify-content: space-between;
  }

  .printStudentNoteCard i {
    align-self: flex-end;
  }
}
