header {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  /* height: 60px; */
}

.headerButton {
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: rgb(200, 203, 250);
  border-radius: 50px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
}

.headerImage {
  width: 100%;
  height: 100%;
  /* background-color: gb(200, 203, 250); */
  border-radius: 50%;
  object-fit: cover;
}
