.homepagina .header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.homepagina h1 {
  font-size: 32px;
  color: var(--orange);
}

.homepagina h2 {
  margin: 20px 0px;
}

.homepagina ul {
  margin-top: 20px;
  margin-left: 10px;
}