.subsectionContainer {
  display: flex;
  width: 100%;
  gap: 20px;
}

.stripContainer {
  max-width: 180px;
  /* margin-right: 20px; */
  min-width: 180px;
}

.categoriesContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.categoryButton {
  /* margin-bottom: 10px; */
  cursor: pointer;
  font-size: 14px;
  font-family: 'ProzaLibreRegular';
  padding: 10px;
  width: 100%;
  border-radius: 10px;
}

.categoryButton:hover {
  background-color: var(--light_orange);
  transition: all 0.4s ease-in-out;
}

.activeCategory {
  animation-name: underline;
  color: white;
  background-color: var(--orange);
  animation-duration: 0.4s;
  animation-timing-function: ease-out;
  /* margin-bottom: 5px; */
}

.activeCategory:hover {
  background-color: var(--orange_hover);
  transition: all 0.4s ease-in-out;
}

@keyframes underline {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

/* 
.adminTableContainer table {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.adminTableContainer thead {
  padding-right: 13px;
  flex: 0 0 auto;
  text-align: left;
}

.adminTableContainer tbody {
  flex: 1 1 auto;
  display: block;
  overflow-y: auto;

}

.adminTableContainer tr {
  width: 100%;
  display: table;
  table-layout: fixed;
  border: 1px solid black;
}

.adminTableContainer th,
.adminTableContainer td {
  padding: 10px;
}

.adminTableContainer input[type='text'],
.adminTableContainer input[type='email'] {
  width: 100%;
} */

/* Cards start */

.cardsContainer {
  height: 100%;
  /* height: calc(100vh - 125px); */
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  gap: 20px;
}

.cardContainer {
  width: 250px;
  height: fit-content;
  border-radius: 10px;
  /* background-color:  */
  border: 1px solid rgb(241, 241, 241);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  margin-bottom: 5px;
}

.fancyCardContainer {
  animation: fancyCardContainer 0.5s ease-in-out both;
}

@keyframes fancyCardContainer {
  0% {
    opacity: 0;
    transform: scale(0.3);
    background-color: rgb(200, 203, 250);
  }
  70% {
    opacity: 1;
    transform: scale(1.05);
    /* background-color: rgb(200, 203, 250); */
  }
  100% {
    transform: scale(1);
  }
}

.cardDeleteButtonContainer {
  display: flex;
  padding: 2.5px;
  width: 100%;
  /* border: 1px solid black; */
}

.cardDeleteButton {
  margin-left: auto;
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background-color: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: inherit;
}

.cardDeleteImage {
  height: 20px;
  width: 20px;
  opacity: 0.1;
}

.cardDeleteImage:hover {
  opacity: 0.5;
}

.cardDeleteImageDisabled {
  opacity: 0.1;
}

.cardDeleteImageDisabled:hover {
  opacity: 0.1;
}

.cardDeleteConfirmationBox {
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* background-color: rgb(250, 224, 200); */
  background-color: rgb(221, 221, 221);
  border-radius: 10px 10px 0 0;
  gap: 10px;
}

.deleteConfirmationMessage {
  color: rgb(0, 0, 0);
  /* font-size: 14px; */
  font-family: 'ProzaLibreRegular';
  /* padding: 5px; */
  /* text-align: center; */
  /* width: 100%; */
}

.cardDeleteConfirmationBox button {
  padding: 5px 25px;
  border: none;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: rgb(200, 203, 250);
  border-radius: 10px;
  cursor: pointer;
  letter-spacing: 1px;
}

.cardProfileImageContainer {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  position: relative;
}

.imageShadow {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0px 5px 5px 0px inset #8b8b8b;
  border-radius: 20px;
  top: 0;
  left: 0;
  border-radius: 50%;
  /* z-index: 3; */
  /* background-color: rgb(200, 203, 250); */
}

.cardProfileImageContainer img {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 5px 5px 0px inset #505050;
  background-color: rgb(221, 221, 221);
  /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
}

.cardProfileImageInput {
  display: none;
}

.cardProfileImageLabel {
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.cardMessageContainer {
  height: fit-content;
  width: 100%;
  display: flex;
}

.cardErrorMessage {
  color: rgb(250, 224, 200);
  background-color: rgb(255, 0, 0);
  padding: 10px;
  text-align: center;
  width: 100%;
}

.cardConfirmMessage {
  background-color: rgb(221, 221, 221);
  padding: 10px;
  text-align: center;
  width: 100%;
}

.cardInputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 5px;
  padding: 0 20px;
}

.cardInputContainer input {
  width: 100%;
  text-align: center;
  padding: 2.5px;
  border-radius: 2.5px;
  border: 1px solid rgb(236, 235, 235);
}

.cardCheckboxContainer div {
  display: flex;
  gap: 10px;
}

.cardStatusContainer select {
  height: fit-content;
  border: none;
  border-radius: 5px;
  padding: 2.5px 3.5px;
  background-color: rgb(236, 235, 235);
}

/* .sharedInput {
  font-size: 1rem;
} */

.cardUpdateButtonContainer {
  width: 100%;
  height: fit-content;
  /* border: 1px solid black; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 10px 10px;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0;
  border: ;
  /* background-color: rgb(200, 203, 250); */
  /* background-color: rgb(221, 221, 221); */
  /* padding: 10px; */
}

.cardUpdateButtonContainer button {
  padding: 5px 25px;
  border: none;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  color: white;
  background-color: rgb(200, 203, 250);
  background-color: var(--orange);
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 1px;
  font-family: 'ProzaLibreRegular';
  width: 80%;
}

.cardContainer button:hover:disabled {
  /* background-color: rgb(212, 214, 244); */
  cursor: initial;
}

.cardDeleteConfirmationBox button:hover,
.cardUpdateButtonContainer button:hover {
  background-color: var(--orange_hover);
  transition: all 0.4s ease-in-out;
}

/* Cards end */
