nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
   var(--sidebar),
   var(--sidebar_d)
     100%
  );
  /* box-shadow: 0px 2.5px 2.5px 0px rgba(0, 0, 0, 0.25); */
  gap: 20px;
  min-width: 70px;
  border-radius: 10px 0 0 10px;
}

.logoContainer {
  margin: 20px 0;
}

.navigationButton {
  width: 40px;
  height: 40px;
  background-color: var(--light_orange);
  border-radius: 5px;
  box-shadow: 0px 5px 10px 0px rgb(174, 99, 7);
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.navigationButton:hover {
  background-color: var(--light_orange_hover);
  transition: all 0.4s ease-in-out;
}

.chosenNavigationButton {
  width: 40px;
  height: 40px;
  background-color: rgb(235, 235, 235);
  border-radius: 5px;
  box-shadow: 0px 5px 5px 0px inset rgb(174, 174, 174);
 
  display: flex;
  /* justify-content: center;  */
  align-items: center; 
  /* transition: all 0.5s linear; */
}

.chosenNavigationButton:hover,
.chosenRoundNavButton:hover {
  background-color: rgb(227, 227, 227);
}

.navigationImage {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  object-fit: cover;
}

.selectedLine {
  position: absolute;
  margin-top: -5px;
  margin-left: -15px;
  width: 5px;
  height: 50px;
  /* background-color: rgb(200, 203, 250); */
  background-color: rgb(235, 235, 235);
  border-radius: 0 2px 2px 0;
  box-shadow: 0px 2.5px 2.5px 0px inset rgb(154, 154, 154);
  transition: background-color 0.25s ease-out;
 
}

.navbarRoundButtonsContainer {
  display: inherit;
  flex-direction: inherit;
  gap: 15px;
  margin: auto 0 20px 0;
}

.roundNavButton {
  width: 40px;
  height: 40px;
  border: none;
  background-color: rgb(200, 203, 250);
  background-color: var(--light_orange);
  border-radius: 50%;
  box-shadow: 0px 5px 5px 0px rgb(41, 41, 41);
  cursor: pointer;
}

.chosenRoundNavButton {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(235, 235, 235);

  box-shadow: 0px 5px 5px 0px inset rgb(174, 174, 174);

}

.roundNavButton:hover {
  background-color: rgb(180, 180, 250);
  background-color: var(--light_orange_hover);
  transition: all 0.4s ease-in-out;
}
