.listContainers-container {
  /* background-color: red; */
  /* height: calc(100% - 100px); */
  max-height: calc(100% - 100px);
}

.listContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  height: 100%;
}

.container-for-listContainer {
  /* background-color: red; */
  height: 100%;
}

.listContainer-for-new {
  overflow: scroll;
  max-height: calc(100% - 10px);
  padding-bottom: 20px;
  /* background-color: rgb(159, 159, 255); */
}


.listContainer-for-schedulesTable {
  overflow: scroll;
  height: calc(100% - 600px);
  /* background-color: rgb(159, 159, 255); */
}

.listPaginationContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
}

.listPaginationContainer-attendance {
  height: calc(100% - 35px);
}

/* for students list and presence lists */
.list {
  border-collapse: collapse;
  width: 100%;
  height: calc(100% -300px);
}

.listContainer .specificStudentsList {
  height: initial;
}

.list thead {
  font-size: 1.1rem;
  position: sticky;
  top: 0;
  text-align: left;
  color: white;
  z-index: 11;
}

.list th {
  background-color: var(--orange);
}

.list th {
  padding: 10px;
}

.list td {
  padding: 0px;
}

.px-for-td {
  padding: 10px 10px !important;
}

.nee-button {
  background-color: rgb(202, 202, 1) !important;
}

.nee-button:hover {
  background-color: rgb(150, 150, 0) !important;
}

.list tr {
  cursor: pointer;
  background-color: white;
}

.list tbody tr:nth-child(even) {
  background-color: var(--yellowish);
}

.list tbody tr td.inactiveStudent {
  color: red;
}

.list tbody tr:nth-child(even):hover {
  background-color: var(--light_orange);
  transition: 0.15s linear;
}

.list tbody tr:nth-child(odd):hover {
  background-color: var(--light_orange);
  transition: 0.15s linear;
}

.list a {
  text-decoration: none;
  color: black;
}

.deleteRowConfirmationBox {
  min-width: 250px;
  border: 2px solid var(--orange_hover);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px;
}

.deleteRowConfirmationBox .listControls {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
}

.listControls>* {
  margin: auto;
}


.listPagination {
  display: flex;
  flex-grow: 3;
  flex-wrap: nowrap;
  align-self: center;
  gap: 5px;
}

.listPagination>div {
  margin: auto;
}

.paginationButton {
  padding: 5px 10px;
  border: none;
  outline: none;
  border-radius: 5px;
}

.paginationButton:hover {
  background-color: rgb(180, 180, 250);
  background-color: var(--orange);
  transition: all 0.4s ease-in-out;
}

.selectedPaginationButton {
  background-color: var(--light_orange);
}

/* for students list and presence lists */

/*  style changes for the checkbox */
.checkboxoflist input {
  vertical-align: middle;
}

.checkboxoflist label {
  font-size: 13px;
}


.cell.trashcan_container {
  display: flex;
  /* Use flexbox to align items */
  justify-content: flex-end;
  /* Align items to the right */
}

.cell.trashcan_container {
  margin-right: 0px;
}