.cyclinginputButtonContainer {
  position: relative;
  max-width: 110px;
}

.listContainer .cyclinginputButtonContainer input {
  padding:  10px;
  padding-left: 17px;
  background-color: transparent;
  border: none;
}


.listContainer .protection_wrap .cyclinginputButtonContainer input {
  padding:  0px;
  padding-left: 0px;
  background-color: transparent;
  border: none;
}

.listContainer .cyclinginputButton {
  position: absolute;
  top: 0;
  left: 0;
  background: transparent;
  opacity: 0.4;
  width: 100%;
  height: 100%;
  border: none;
}


.listContainer .cyclinginputButton::before,
.listContainer .cyclinginputButton::after {
  position: absolute;
  content: "";
  width: 100%;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  z-index: 1000;
  background-repeat: no-repeat;
}

 
.listContainer .cyclinginputButton.animate::before {
  top: -70%;
  background-image:
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, #7d2ae8 20%, transparent 30%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, var(--dark_orange) 20%, transparent 20%),
    radial-gradient(circle, var(--orange) 20%, transparent 20%),
    radial-gradient(circle, var(--light_orange) 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%, 15% 15%, 20% 20%, 18% 18%;
  animation: greentopBubbles ease-in-out 0.6s forwards ;
}

@keyframes greentopBubbles {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%, 10% 90%, 30% 90%, 50% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%, 10% 80%, 30% 60%, 50% 40%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%, 10% 70%, 30% 50%, 50% 30%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.cyclinginputButton.animate::after {
  bottom: -70%;
  background-image:
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, #7d2ae8 15%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, #7d2ae8 20%, transparent 20%),
    radial-gradient(circle, var(--dark_orange) 20%, transparent 20%),
    radial-gradient(circle, var(--orange) 20%, transparent 20%),
    radial-gradient(circle, var(--light_orange) 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 15% 15%, 20% 20%, 18% 18%;
  animation: greenbottomBubbles ease-in-out 0.6s forwards ;
}

@keyframes greenbottomBubbles {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%, 20% -10%, 40% 10%, 60% -10%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%, 20% 80%, 40% 60%, 60% 40%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%, 20% 90%, 40% 70%, 60% 50%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}
