@media print {
  .stripContainer {
    display: none;
  }

  nav {
    display: none;
  }

  .stripContainer {
    display: none;
  }

  .sectionControlsContainer {
    display: none;
  }
}

.infoOnLoading {
  padding: 0px 20px;
}
