 
:root {
  --dark_orange: #e25e3e;     
  --orange: #e5741e;        
  --orange_hover: #ab4a00;        
  --light_orange: #ffe0b8;       
  --light_orange_hover: #ffb24d;      
  --yellowish: #fff9ec;  
  
  --sidebar: #e5741e;
  --sidebar_d:  #e5741e;
  --mainbg:  #ffcba2;
}


@font-face {
  font-family: 'CormorantGaramondRegular';
  src: local('CormorantGaramondRegular'),
    url('../fonts/Cormorant_Garamond/CormorantGaramond-Regular.ttf')
      format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'CormorantGaramondBold';
  src: local('CormorantGaramondBold'),
    url('../fonts/Cormorant_Garamond/CormorantGaramond-Bold.ttf')
      format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'RobotoSlabRegular';
  src: local('RobotoSlabRegular'),
    url('../fonts/Roboto_Slab/RobotoSlab-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'RobotoSlabBold';
  src: local('RobotoSlabBold'),
    url('../fonts/Roboto_Slab//RobotoSlab-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'ProzaLibreRegular';
  src: local('ProzaLibreRegular'),
    url('../fonts/Proza_Libre/ProzaLibre-Regular.ttf') format('truetype');
  font-weight: regular;
}

.root-div {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fffadb;
  display: flex;
}

.root-div-subcontainer {
  margin: auto;
  display: flex;
  padding: 20px;
  align-items: center;
  width: 1500px;
  height: 100%;
}

.outlet {
  position: relative;
  width: 100%;
  max-width: 1480px;
  height: 100%;
  background-color: var(--light_orange);
  border-radius: 0 10px 10px 0;
}

#root {
  height: 100vh;
}

.App {
  border-radius: 0 0 10px 10px;
  min-height: 100%;
  height: 100%;
  width: 100%;
  /* max-width: 1480px; */
  margin: 0 auto;
  /* background: rgb(245, 245, 245);
  background: linear-gradient(
    180deg,
    rgba(245, 245, 245, 1) 28%,
    rgba(225, 225, 225, 1) 62%,
    rgba(210, 210, 210, 1) 80%
  ); */
  background-color: var(--mainbg);
  display: flex;
  position: relative;
}

main {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 20px;
  gap: 20px;
  height: 100%;
  flex-grow: 1;
}

.sectionContainer {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  print-color-adjust: exact;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.25);
  height: calc(100% - 0px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex-grow: 1;
}

.sectionHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.sectionTitleContainer {
  display: flex;
  gap: 10px;
}

.sectionTitle {
  font-size: 1.2rem;
}

.sectionTitlePoint {
  height: 25px;
  width: 10px;
  background-color: var(--orange);
  border: 1px solid var(--orange);
  border-radius: 5px;
}

.sectionTitlePointAnimatedOutside {
  height: 25px;
  width: 10px;
  border-radius: 5px;
  box-shadow: 0px 2.5px 2.5px 0 inset rgb(154, 154, 154);
  position: relative;
  overflow: hidden;
}

.sectionTitlePointAnimatedInside {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgb(200, 203, 250);
  background-color: var(--orange);
  animation: sectionTitlePointAnimatedInside 2s infinite linear;
  transform-origin: bottom;
}

@keyframes sectionTitlePointAnimatedInside {
  0% {
    height: 0%;
    /* border-radius: 0px 0px 5px 5px; */
  }

  45% {
    box-shadow: 0px 2.5px 2.5px 0 inset rgb(154, 154, 154);
  }

  50% {
    height: 100%;
    /* border-radius: 5px 5px 5px 5px; */
  }
  65% {
    box-shadow: 0px 2.5px 2.5px 0 inset rgb(154, 154, 154);
  }

  100% {
    height: 0%;
    /* border-radius: 0px 0px 5px 5px; */
  }
}

.mainbg { 
  background-color: var(--mainbg);
  border-radius: 0 10px 10px 0;
}


.middle_cell {
  width: 100%;
}



.sectionControlsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.sectionControlContainer {
  display: flex;
  gap: 10px;
  margin-left: auto;
}

.sectionControlButton {
  width: 25px;
  height: 25px;
  background-color: rgb(200, 203, 250);
  background-color: var(--orange);
  border-radius: 5px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 2.5px 2.5px 0 rgb(174, 174, 174);
  padding: 1px;
}

.sectionControlButtonShade {
  width: 25px;
  height: 25px;
  background-color: var(--orange);
  border-radius: 5px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 2.5px 2.5px 0 rgb(174, 174, 174);
}

.sectionControlButton:hover {
  background-color: rgb(180, 180, 250);
  background-color: var(--orange_hover);
  transition: all 0.4s ease-in-out;
}

.sectionControlImage {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex; 
  justify-content: center;
}

.sectionSearchContainer {
  display: flex;
  box-shadow: 0px 2.5px 2.5px 0 rgb(174, 174, 174);
  border-radius: 5px;
}

.sectionSearchInput {
  height: 25px;
  width: 170px;
  border-radius: 5px 0px 0px 5px;
  border: 0.5px rgb(200, 203, 250) solid;
  border: none;
  padding: 0 10px;
  color: white;
  background-color: var(--orange);
  font-size: 1.1rem;
  outline: none;
}

.sectionSearchInput::placeholder {
  color: white;
}

.sectionSearchInput:hover {
  transition: all 0.4s ease-in-out;
}

.sectionSearchButton {
  padding: 1px;
  width: 25px;
  height: 25px;

  background-color: var(--orange);
  border-radius: 0px 5px 5px 0;
  cursor: pointer;
  border: none;
}

input[type='search']::-webkit-search-cancel-button {
  appearance: none;
  cursor: pointer;
  background-color: transparent;
}

.faded {
  opacity: 0.38;
}

/* popup container */
.popupContainer {
  position: absolute;
  left: -70px;
  width: calc(100% + 70px);
  /* max-width: calc(1480px  - 20px); */
  height: calc(100% - 0px);
  z-index: 1;
  /* background-color: rgba(0, 0, 0, 0.396); */
  animation: popupContainer 0.5s linear;
}

@keyframes popupContainer {
  0% {
    opacity: 0.33;
    transform: scale(0.93);
  }

  50% {
    opacity: 0.66;
    transform: scale(0.96);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

/* popup container close button */
.popupContainerClose {
  position: inherit;
  right: 10px;
  top: 10px;
  width: 25px;
  height: 25px;
  z-index: 2;
  background-color: rgb(255, 255, 255);
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  box-shadow: 0px 1.5px 1.5px rgb(170, 170, 170);
}

.popupContainerClose:hover {
  background-color: rgb(230, 230, 230);
}

.popupContainerClose img {
  width: 100%;
  height: 100%;
}
/* popup container close button */
/* popup container */

/* worded button */
.wordedButton {
  color: white;
  padding: 5px;
  background-color: var(--orange);
  border-radius: 5px;
  cursor: pointer;
  border: none;
  box-shadow: 0px 2.5px 2.5px 0 rgb(174, 174, 174);
}

.wordedButton:hover {
  background-color: var(--orange_hover);
}
/* worded button */

.notificationMessageInControls {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(222, 197, 33);
  padding: 1px 5px;
  border-radius: 5px;
}

.notificationMessageInControls2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(222, 197, 33);
  padding: 0px 5px;
  border-radius: 5px;
}

.nowrap {
  white-space: nowrap;
}

.arrow {
  height: 10px;
  width: 10px;
}

.bigclosebox {
  width: 600px;
  display: flex;
}

.bigclosebox article {
  display: flex;
  justify-content: space-between;
}


.bigclosebox h1 {
  font-size: 22px;
  margin: 10px 0px;
}

.bigclosebox article button {
  margin-top: -20px;
  background-color: transparent;
  cursor: pointer;
  border: none;
}

.bigclosebox p {
  border-bottom: 1px solid gray;
  padding-bottom: 20px;
}

.bigclosebox > div {
  align-self:flex-end;
  width: fit-content;
}