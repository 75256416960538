.green {
  background-color: rgb(144, 238, 144);
}

.evaluationList .green:hover {
  background-color: rgb(110, 210, 144);
}

.yellow {
  background-color: rgb(255, 255, 0);
}

.evaluationList .yellow:hover {
  background-color: rgb(255, 240, 0);
}

.orange {
  background-color: rgb(255, 165, 0);
}

.evaluationList .orange:hover {
  background-color: rgb(220, 140, 0);
}

.red {
  background-color: rgb(255, 0, 0);
}

.evaluationList .red:hover {
  background-color: rgb(220, 0, 0);
}

.evaluationList {
  border-collapse: collapse;
  width: 100%;
}

.evaluationList thead {
  font-size: 1.1rem;
  position: sticky;
  top: 0;
  text-align: left;
}

.evaluationList th {
  background-color: var(--orange);
  color: white;
  padding: 10px;
}

.evaluationList tr {
  cursor: pointer;
}


.evaluationList td {
  padding: 10px;
  color: black;
}

.evaluationInfo {
  width: 180px;
  border: 0.5px solid transparent;
  border-radius: 10px;
  padding: 10px;
}
